import React from 'react'


export default function SlidebeanSlide({url, height=50}) {

    return(
        <>
            <div
            style={{
                position: "relative",
                width: "100%",
                height: height,
                paddingBottom: "62.5%"
            }}
            >
                <iframe
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                    src={url}
                    frameBorder={0}
                    allowFullScreen="allowFullScreen"
                />
            </div>
        </>
        )

    }
