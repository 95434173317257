import React from "react"

import img_quote from "../images/new_quote.png"

export default function FeaturedTestimonial(props) {
    return (
        <>
        <div className="col-lg-5 col-sm-6">
          <div className="text_block">
            <div className="user_block">
              <img src={img_quote} />
              <div className="user_img">
                <img
                  className="thumbnail_img"
                  src={props.thumb_pic}
                  alt={props.thumb_alt}
                />
              </div>
            </div>
            <div className="content_block">
              <h4>
                {props.testimonial_headline}<b> ”</b>
              </h4>
              <p>
                {props.testimonial_description}
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-sm-6">
          <div className="res_video">
            <a href={props.testimonial_url} title={props.seo_link_title}>
                <img src={props.video_preview_pic} />
                <div class="play-icon"><i class="fa fa-play" aria-hidden="true"></i></div>
            </a>
          </div>
        </div>
        </>
    )
}